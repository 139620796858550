import { InferGetServerSidePropsType } from 'next';

import { AdminRoute } from '@admin/routing/AdminRoute';
import { getNextAdminServerSidePropsWrapper } from '@admin/routing/serverSideProps';
import { IndexPage, State } from '@admin/templates';
import { getNewsCategories } from '@admin/templates/news-category/helpers';
import { NewsCategory } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { PreparePropsReturn } from '@common/types/DefaultProps';

type PageProps = { state: State; categories: NewsCategory[] };

const prepareProps = async (contextData: ContextData): PreparePropsReturn<PageProps> => {
    const categories = await getNewsCategories({ contextData, isClientSide: false });
    const state = {
        dataIsOutdated: false,
    } satisfies State;

    return {
        props: {
            state,
            categories,
        },
    };
};

export const getServerSideProps = getNextAdminServerSidePropsWrapper<PageProps>(
    AdminRoute.Index,
    prepareProps,
);

export type IndexPageProps = InferGetServerSidePropsType<typeof getServerSideProps>;

export default IndexPage;
